import React, { useState, useEffect } from 'react';
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Typography,
    makeStyles,
    TextField,
    TableSortLabel,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import { Edit as EditIcon, Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';
import CreateCouponModal from './CreateCouponModal';
import CouponApi from '../../network/couponApi';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: theme.spacing(3),
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(3),
    },
    table: {
        minWidth: 650,
    },
    tableHead: {
        backgroundColor: theme.palette.primary.main,
    },
    tableHeadCell: {
        color: theme.palette.common.white,
        fontWeight: 'bold',
    },
    tableCell: {
        padding: theme.spacing(2),
    },
    notesCell: {
        width: 200,
        maxWidth: 200,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&:hover': {
            overflow: 'auto',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
        },
    },
    searchField: {
        marginBottom: theme.spacing(2),
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    activeChip: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.common.white,
    },
    inactiveChip: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.common.white,
    },
    actionButtons: {
        '& > *': {
            marginRight: theme.spacing(1),
        },
    },
}))

const Coupons = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [coupons, setCoupons] = useState([]);
    const [editingCoupon, setEditingCoupon] = useState(null);
    const [error, setError] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [orderBy, setOrderBy] = useState('name');
    const [order, setOrder] = useState('asc');
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [couponToDelete, setCouponToDelete] = useState(null);

    useEffect(() => {
        fetchCoupons();
    }, []);

    const fetchCoupons = () => {
        CouponApi.getInstance().getCoupons()
            .then((response) => {
                if (response) {
                    setCoupons(response);
                }
            })
            .catch((error) => {
                console.error('Error fetching coupons:', error);
                setError('An error occurred while fetching coupons.');
            });
    };

    const handleOpen = (coupon = null) => {
        setOpen(true);
        setEditingCoupon(coupon);
        setError('');
    };

    const handleClose = () => {
        setOpen(false);
        setEditingCoupon(null);
        setError('');
    };

    const handleSubmit = (coupon) => {
        const { couponName, couponAmount, discountPct, discountAmount, couponExpiration, couponNotes, isActive, partner } = coupon;

        if (!couponName || !couponAmount) {
            setError('Please fill in all required fields.');
            return;
        }

        if (isActive && !discountPct && !discountAmount) {
            setError('Please provide either a discount percentage or amount.');
            return;
        }

        if (isActive && discountPct && discountAmount) {
            setError('Please provide only one type of discount (either percentage or amount).');
            return;
        }

        const couponData = {
            name: couponName,
            amount: parseInt(couponAmount, 10),
            expirationDate: new Date(couponExpiration).getTime(),
            active: isActive,
            discountPct: discountPct ? parseFloat(discountPct) : 0,
            discountAmount: discountAmount ? parseFloat(discountAmount) : 0,
            notes: couponNotes,
            partner: partner || null, // Include partner as optional
        };

        const apiCall = editingCoupon
            ? CouponApi.getInstance().updateCoupon(editingCoupon._id, couponData)
            : CouponApi.getInstance().createCoupon(couponData);

        apiCall
            .then((response) => {
                if (response && !response.err) {
                    if (editingCoupon) {
                        setCoupons(coupons.map(c => c._id === response._id ? response : c));
                    } else {
                        setCoupons([...coupons, response]);
                    }
                    handleClose();
                } else {
                    setError(response.err || 'Failed to create/update coupon. Please try again.');
                }
            })
            .catch((error) => {
                console.error('Error creating/updating coupon:', error);
                setError(error.response?.data?.error || 'An error occurred while creating/updating the coupon.');
            });
    };



    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleDeleteClick = (coupon) => {
        setCouponToDelete(coupon);
        setDeleteConfirmOpen(true);
    };

    const handleDeleteConfirm = () => {
        if (couponToDelete) {
            CouponApi.getInstance().deleteCoupon(couponToDelete._id)
                .then(() => {
                    setCoupons(coupons.filter(c => c._id !== couponToDelete._id));
                    setDeleteConfirmOpen(false);
                    setCouponToDelete(null);
                })
                .catch((error) => {
                    console.error('Error deleting coupon:', error);
                    setError('An error occurred while deleting the coupon.');
                });
        }
    };

    const handleDeleteCancel = () => {
        setDeleteConfirmOpen(false);
        setCouponToDelete(null);
    };


    const filteredCoupons = coupons?.filter(coupon =>
        coupon.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (coupon.notes && coupon.notes.toLowerCase().includes(searchTerm.toLowerCase()))
    ) || [];

    const sortedCoupons = filteredCoupons?.sort((a, b) => {
        const aValue = a[orderBy];
        const bValue = b[orderBy];

        if (orderBy === 'expirationDate') {
            return order === 'asc' ? aValue - bValue : bValue - aValue;
        }

        if (['amount', 'discountPct', 'discountAmount'].includes(orderBy)) {
            return order === 'asc' ? aValue - bValue : bValue - aValue;
        }

        if (typeof aValue === 'string' && typeof bValue === 'string') {
            return order === 'asc'
                ? aValue.localeCompare(bValue)
                : bValue.localeCompare(aValue);
        }


        if (aValue < bValue) return order === 'asc' ? -1 : 1;
        if (aValue > bValue) return order === 'asc' ? 1 : -1;
        return 0;
    });

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Typography variant="h4" component="h1">Coupons</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => handleOpen()}
                >
                    Create Coupon
                </Button>
            </div>

            <TextField
                className={classes.searchField}
                label="Search coupons"
                variant="outlined"
                fullWidth
                value={searchTerm}
                onChange={handleSearchChange}
            />

            <TableContainer component={Paper}>
                <Table className={classes.table}>
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            {['name', 'amount', 'discountPct', 'discountAmount', 'partner', 'expirationDate', 'notes', 'active'].map((column) => (
                                <TableCell
                                    key={column}
                                    sortDirection={orderBy === column ? order : false}
                                    className={classes.tableHeadCell}
                                    align="left"
                                >
                                    <TableSortLabel
                                        active={orderBy === column}
                                        direction={orderBy === column ? order : 'asc'}
                                        onClick={() => handleSort(column)}
                                    >
                                        {column === 'discountPct' ? 'Discount %' :
                                            column === 'discountAmount' ? 'Discount $/₪' :
                                                column.charAt(0).toUpperCase() + column.slice(1)}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                            <TableCell className={classes.tableHeadCell} align="left">Actions</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {sortedCoupons?.map((coupon) => (
                            <TableRow key={coupon._id}>
                                <TableCell className={classes.tableCell} align="left">{coupon.name}</TableCell>
                                <TableCell className={classes.tableCell} align="left">{coupon.amount}</TableCell>
                                <TableCell className={classes.tableCell} align="left">{coupon.discountPct}%</TableCell>
                                <TableCell className={classes.tableCell} align="left">{coupon.discountAmount} $/₪</TableCell>
                                <TableCell className={classes.tableCell} align="left">{coupon.partner || '-'}</TableCell>
                                <TableCell className={classes.tableCell} align="left">
                                    {new Date(coupon.expirationDate).toLocaleDateString()}
                                </TableCell>
                                <TableCell className={`${classes.tableCell} ${classes.notesCell}`} align="left" title={coupon.notes}>
                                    {coupon.notes || '-'}
                                </TableCell>
                                <TableCell className={classes.tableCell} align="left">
                                    <Chip
                                        label={coupon.active ? 'Active' : 'Inactive'}
                                        className={`${classes.chip} ${coupon.active ? classes.activeChip : classes.inactiveChip}`}
                                    />
                                </TableCell>
                                <TableCell className={classes.actionButtons} align="left">
                                    <IconButton onClick={() => handleOpen(coupon)} size="small" color="primary">
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleDeleteClick(coupon)} size="small" color="secondary">
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>

            <CreateCouponModal
                open={open}
                onClose={handleClose}
                onSubmit={handleSubmit}
                coupon={editingCoupon}
                error={error}
            />

            <Dialog
                open={deleteConfirmOpen}
                onClose={handleDeleteCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the "{couponToDelete?.name}" coupon?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="secondary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
};

export default Coupons;