import React, {Component, useContext} from 'react';

import CircularProgress from "@material-ui/core/CircularProgress";
import {withRouter} from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import UsersApi from "../../network/usersApi";
import {createFilterOptions} from "@material-ui/lab";
import {Context} from '../../states/Store'

const filterOptions = createFilterOptions({
    stringify: option => getFilterString(option)
});

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
};

function getFilterString(user) {
    return user.userId +
        (user.userName ? user.userName : "") +
        (user.email ? user.email : "") +
        (user.phone ? user.phone : "")
}

function AdvancedSearch(props) {
    const [state, dispatch] = useContext(Context);
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [filteredOptions, setFilteredOptions] = React.useState([]);
    const loading = open && options.length === 0;

    const onInputChange = (event, value, reason) => {
        if (value.length > 2) {

            const example = "+972 52-856-6355";
            const validNum = "0503797275";

            if ((value.trim().startsWith("5") || value.includes("972")) && value.length <= example.length && value.length !== validNum.length) {
                value = value.replace(/\D/g, '').replace(/\s+/g, '');
                value = value.replace("972", "0")
                window.alert(value)
            }


            (async () => {
                const users = await UsersApi.getInstance().searchBy(value);
                if (!!users && users.length > 0) {
                    setFilteredOptions(users.map(user => getFilterString(user)))
                    console.log(users[0].userId)
                    console.log(users[0])
                    setOptions(users);
                } else {
                    setOptions([{}])
                }
            })();
        }
    };

    const onOptionChange = (event, value, reason) => {
        if (reason.includes('select')) {
            openInNewTab(`/main/user/?q=${value.userId}&privileges=${state.user.privileges}`);
        }
    };

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        if (active) {
            setOptions([]);
        }

        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <div style={{width: '100%', justifyContent: 'center', display: 'flex', position: 'absolute'}}>
            <Autocomplete
                id="advanced-search"
                style={{width: 500}}
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                getOptionSelected={(option, value) => option.userId === value.userId}
                getOptionLabel={(option) => !!option.userName ? `${option.userName} ${!!option.email ? ' | ' + option.email : ""}` : ""}
                options={options}
                loading={loading}
                filterOptions={filterOptions}
                noOptionsText={"No users found"}
                onChange={onOptionChange}
                onInputChange={onInputChange}
                renderInput={renderInput()}
            />
        </div>
    );

    function renderInput() {
        return (params) => (
            <TextField
                {...params}
                label="Search user by ID, Phone, Email or Name"
                variant="outlined"
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20}/> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                }}
            />
        )
    }
}


export default withRouter(AdvancedSearch);
