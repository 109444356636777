import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SearchIcon from '@material-ui/icons/Search';
import UsersIcon from '@material-ui/icons/Group';
import SystemIcon from '@material-ui/icons/Settings';
import CouponsIcon from '@material-ui/icons/TableChart';
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import {Context} from '../states/Store'


const useStyles = makeStyles({
    root: {
        width: '100%',
        position: 'fixed',
        bottom: 0
    },
});

export default function SimpleBottomNavigation() {
    const history = useHistory();
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const [state, dispatch] = useContext(Context);

    function tabClicked(param) {
        localStorage.removeItem("appState");
        if (isAdmin() || isViewer()) {
            navigateToAdminPage(param)
        } else if (isSupport()) {
            navigateToSupportPage(param)
        } else if (isContractor()) {
            navigateToContractorPage(param)
        } else {
            navigateToAgentPage(param);
        }
    }

    function navigateToAgentPage(param) {
        if (param === 1) {
            history.push("/main/agent/report")
        } else if (param === 2) {
            history.push("/main/agent/subscription")
        }
    }

    function navigateToAdminPage(param) {
        if (param === 1) {
            history.push("/main/dashboard")
        } else if (param === 2) {
            history.push(
                {
                    pathname: "/main/users",
                    state: {privileges: state.user.privileges}
                }
            )
        } else if (param === 3) {
            history.push(
                {
                    pathname: "/main/system",
                    state: {privileges: state.user.privileges}
                }
            )
        } else if (param === 4) {
            history.push("/main/search")
        } else if (param === 5) {
            history.push("/main/coupons")
        }
    }

    function navigateToContractorPage(param) {
        if (param === 1) {
            history.push(
                {
                    pathname: "/main/users",
                    state: {privileges: state.user.privileges}
                }
            )
        } else if (param === 2) {
            history.push("/main/search")
        }
    }

    function navigateToSupportPage(param) {
        if (param === 1) {
            history.push(
                {
                    pathname: "/main/users",
                    state: {privileges: state.user.privileges}
                }
            )
        } else if (param === 2) {
            history.push("/main/search")
        } else if (param === 3) {
            history.push("/main/system")
        } else if (param === 5) {
            history.push("/main/coupons")
        }
    }

    let bottomBar = null;

    function isAdmin() {
        let privileges = state.user.privileges;
        return privileges === 'admin'
    }

    function isViewer() {
        let privileges = state.user.privileges;
        return privileges === 'viewer'
    }

    function isContractor() {
        let privileges = state.user.privileges;
        return privileges === 'contractor'
    }

    function isSupport() {
        let privileges = state.user.privileges;
        return privileges === 'support'
    }

    if (!state.error && state.user) {
        if (isAdmin() || isViewer()) {
            bottomBar = getAdminBottomNavBar()
        } else if (isSupport()) {
            bottomBar = getSupportBottomNavBar();
        } else if (isContractor()) {
            bottomBar = getContractorBottomNavBar()
        } else {
            bottomBar = getAgentBottomNavBar();
        }
    }

    return (
        bottomBar
    );

    function getAdminBottomNavBar() {
        return <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            showLabels
            className={classes.root}
        >
            <BottomNavigationAction label="Dashboard" onClick={() => tabClicked(1)} icon={<RestoreIcon/>}/>
            <BottomNavigationAction label="Users" onClick={() => tabClicked(2)} icon={<UsersIcon/>}/>
            <BottomNavigationAction label="System" onClick={() => tabClicked(3)} icon={<SystemIcon/>}/>
            <BottomNavigationAction label="Advanced Search" onClick={() => tabClicked(4)} icon={<SearchIcon/>}/>
            <BottomNavigationAction label="Coupons" onClick={() => tabClicked(5)} icon={<CouponsIcon/>}/>
        </BottomNavigation>
    }

    function getAgentBottomNavBar() {
        return <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            showLabels
            className={classes.root}
        >
            <BottomNavigationAction label="דו״ח מצב" onClick={() => tabClicked(1)} icon={<SystemIcon/>}/>
            <BottomNavigationAction label="יצירת מנויים" onClick={() => tabClicked(2)}
                                    icon={<SearchIcon/>}/>
        </BottomNavigation>
    }

    function getContractorBottomNavBar() {
        return <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            showLabels
            className={classes.root}
        >
            <BottomNavigationAction label="Users" onClick={() => tabClicked(1)} icon={<UsersIcon/>}/>
            <BottomNavigationAction label="Advanced Search" onClick={() => tabClicked(2)} icon={<SearchIcon/>}/>
        </BottomNavigation>
    }

    function getSupportBottomNavBar() {
        return <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            showLabels
            className={classes.root}
        >
            <BottomNavigationAction label="Users" onClick={() => tabClicked(1)} icon={<UsersIcon/>}/>
            <BottomNavigationAction label="Advanced Search" onClick={() => tabClicked(2)} icon={<SearchIcon/>}/>
            <BottomNavigationAction label="System" onClick={() => tabClicked(3)} icon={<SystemIcon/>}/>
        </BottomNavigation>
    }
}
