import Network from "./network";

export default class CouponApi {
    static instance = null;

    static getInstance() {
        if (CouponApi.instance == null) {
            CouponApi.instance = new CouponApi();
        }
        return this.instance;
    }

    createCoupon(couponData) {
        return this.sendRequest('POST', 'coupons', couponData);
    }

    getCoupons() {
        return this.sendRequest('GET', 'coupons');
    }

    updateCoupon(couponId, couponData) {
        return this.sendRequest('PUT', `coupons/${couponId}`, couponData);
    }

    deleteCoupon(couponId) {
        return this.sendRequest('DELETE', `coupons/${couponId}`);
    }


    sendRequest(method, endpoint, body = null) {
        const url = `${Network.getInstance().getBaseUrl()}${endpoint}`;
        const options = {
            method: method,
            headers: Network.getInstance().getHeaders()
        };

        if (body) {
            options.body = JSON.stringify(body);
        }

        return fetch(url, options)
            .then((response) => {
                if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                    Network.getInstance().removeAuthentication();
                    return null;
                }
                return response.json();
            })
            .catch(console.log);
    }
}