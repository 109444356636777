import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
    Grid,
    makeStyles,
    Typography
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%',
    },
    formControl: {
        marginBottom: theme.spacing(2),
    },
    errorText: {
        color: theme.palette.error.main,
        marginTop: theme.spacing(2),
    },
}));

const CreateCouponModal = ({ open, onClose, onSubmit, coupon, error }) => {
    const classes = useStyles();
    const [formData, setFormData] = useState({
        couponName: '',
        couponAmount: '',
        discountPct: '',
        discountAmount: '',
        couponExpiration: '',
        couponNotes: '',
        isActive: true,
        partner: '', // Add partner field
    });

    useEffect(() => {
        if (open) {
            if (coupon) {
                setFormData({
                    couponName: coupon.name,
                    couponAmount: coupon.amount.toString(),
                    discountPct: coupon.discountPct ? coupon.discountPct.toString() : '',
                    discountAmount: coupon.discountAmount ? coupon.discountAmount.toString() : '',
                    couponExpiration: new Date(coupon.expirationDate).toISOString().split('T')[0],
                    couponNotes: coupon.notes || '',
                    isActive: coupon.active,
                    partner: coupon.partner || '', // Set partner field
                });
            } else {
                // Reset form data when opening a new form
                setFormData({
                    couponName: '',
                    couponAmount: '',
                    discountPct: '',
                    discountAmount: '',
                    couponExpiration: '',
                    couponNotes: '',
                    isActive: true,
                    partner: '', // Reset partner field
                });
            }
        }
    }, [open, coupon]);

    const handleChange = (event) => {
        const { name, value, checked } = event.target;
        if (name === 'isActive') {
            setFormData((prevState) => ({ ...prevState, [name]: checked }));
        } else if (name === 'discountPct') {
            setFormData((prevState) => ({ ...prevState, [name]: value, discountAmount: '' }));
        } else if (name === 'discountAmount') {
            setFormData((prevState) => ({ ...prevState, [name]: value, discountPct: '' }));
        } else {
            setFormData((prevState) => ({ ...prevState, [name]: value }));
        }
    };

    const handleSubmit = () => {
        onSubmit(formData);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>{coupon ? 'Edit Coupon' : 'Create a New Coupon'}</DialogTitle>
            <DialogContent>
                <form className={classes.form} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.formControl}
                                name="couponName"
                                label="Coupon Name"
                                fullWidth
                                value={formData.couponName}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                className={classes.formControl}
                                name="couponAmount"
                                label="Coupon Amount"
                                type="number"
                                fullWidth
                                value={formData.couponAmount}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                className={classes.formControl}
                                name="couponExpiration"
                                label="Expiration Date"
                                type="date"
                                fullWidth
                                value={formData.couponExpiration}
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                className={classes.formControl}
                                name="discountPct"
                                label="Discount Percentage"
                                type="number"
                                fullWidth
                                value={formData.discountPct}
                                onChange={handleChange}
                                disabled={!!formData.discountAmount}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                className={classes.formControl}
                                name="discountAmount"
                                label="Discount Amount ($/₪)"
                                type="number"
                                fullWidth
                                value={formData.discountAmount}
                                onChange={handleChange}
                                disabled={!!formData.discountPct}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.formControl}
                                name="partner"
                                label="Partner"
                                fullWidth
                                value={formData.partner}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.formControl}
                                name="couponNotes"
                                label="Coupon Notes"
                                fullWidth
                                multiline
                                minRows={3}
                                value={formData.couponNotes}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.isActive}
                                        onChange={handleChange}
                                        name="isActive"
                                        color="primary"
                                    />
                                }
                                label="Active"
                            />
                        </Grid>
                    </Grid>
                </form>
                {error && (
                    <Typography variant="body2" className={classes.errorText}>
                        {error}
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary" variant="contained">
                    {coupon ? 'Update' : 'Create'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateCouponModal;
